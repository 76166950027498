import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Typography from '@material-ui/core/Typography';
import anime from 'animejs';

import WordSplit from './WordSplit';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 56.75px)',
    },
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 78.7px)',
    },
    [theme.breakpoints.up('md')]: {
      height: '100vh',
    },

    '& .carousel .slide .legend': {
      borderRadius: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
      right: 0,
      margin: '0 auto',
      paddingRight: 30,
      paddingLeft: 30,
      height: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .text': {
        color: 'black',
        fontWeight: 500,
        fontSize: '2.8em',
        [theme.breakpoints.down('lg')]: {
          fontSize: 28,
        },
        [theme.breakpoints.down('md')]: {
          fontSize: 24,
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: 20,
        },
        '& .word': {
          display: 'inline-block',
          whiteSpace: 'pre',
        },
      },
    },
  },
}));

const Slideshow = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { allFile } = useStaticQuery(graphql`
    query SlidesQuery {
      allFile(
        filter: {
          relativeDirectory: { eq: "slideshow" }
          extension: { regex: "/(jpg)/" }
        }
        sort: { fields: base }
      ) {
        edges {
          node {
            base
            id
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  const Images = allFile.edges;
  const Img1 = Images.filter((img) => img.node.base === 'slide1.jpg');
  const Img2 = Images.filter((img) => img.node.base === 'slide2.jpg');
  const Img3 = Images.filter((img) => img.node.base === 'slide3.jpg');
  const Img4 = Images.filter((img) => img.node.base === 'slide4.jpg');
  const Img5 = Images.filter((img) => img.node.base === 'slide5.jpg');
  const Img6 = Images.filter((img) => img.node.base === 'slide6.jpg');

  const slides = [
    {
      name: Img1[0].node.base,
      image: Img1,
      legend: t('SlideShow.slide1'),
    },
    {
      name: Img2[0].node.base,
      image: Img2,
      legend: t('SlideShow.slide2'),
    },
    {
      name: Img3[0].node.base,
      image: Img3,
      legend: t('SlideShow.slide3'),
    },
    {
      name: Img4[0].node.base,
      image: Img4,
      legend: t('SlideShow.slide4'),
    },
    {
      name: Img5[0].node.base,
      image: Img5,
      legend: t('SlideShow.slide5'),
    },
    {
      name: Img6[0].node.base,
      image: Img6,
      legend: t('SlideShow.slide6'),
    },
  ];

  const indicatorStyles = {
    border: '2px solid #e42229',
    width: 20,
    height: 20,
    borderRadius: '50%',
    display: 'inline-block',
    margin: '0 10px',
    cursor: 'pointer',
  };

  const [currentSlide, setCurrentSlide] = useState();
  const handleChangeSlide = (slide) => {
    setCurrentSlide(slide);
  };

  useEffect(() => {
    anime
      .timeline()
      .add({
        targets: '.selected .legend .text .word',
        translateX: [100, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: 'easeOutExpo',
        duration: 1000,
        delay: (el, i) => 800 + 30 * i,
      })
      .add({
        targets: '.selected .legend .text .word',
        translateX: [0, -30],
        opacity: [1, 0],
        easing: 'easeInExpo',
        duration: 1100,
        delay: (el, i) => 3000 + 30 * i,
      });

    anime
      .timeline()
      .add({
        targets: '.selected .legend',
        height: [0, 100],
        easing: 'easeOutExpo',
        duration: 1100,
        delay: 200,
      })
      .add({
        targets: '.selected .legend',
        opacity: [1, 0],
        easing: 'easeInExpo',
        duration: 800,
        delay: 5000,
      });
  }, [currentSlide]);

  return (
    <div className={classes.root}>
      <Carousel
        autoPlay
        showThumbs={false}
        showStatus={false}
        stopOnHover={false}
        infiniteLoop
        interval={8000}
        transitionTime={1000}
        swipeScrollTolerance={20}
        preventMovementUntilSwipeScrollTolerance
        onChange={handleChangeSlide}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                style={{
                  ...indicatorStyles,
                  background: '#e42229',
                }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
              />
            );
          }
          return (
            <li
              style={indicatorStyles}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {slides.map((slide, i) => (
          <div key={slide.name} style={{ height: '100%' }}>
            <GatsbyImage
              image={slide.image[0].node.childImageSharp.gatsbyImageData}
              alt={slide.name}
              placeholder="blurred"
              style={{ height: '100%' }}
            />
            {slide.legend ? (
              <div className="legend">
                <Typography variant="h6" className="text">
                  <WordSplit text={slide.legend} />
                </Typography>
              </div>
            ) : null}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Slideshow;
