import React from 'react';
import { graphql } from 'gatsby';

import Template from '../components/Template';
import Slideshow from '../components/Slideshow';
import Metadata from '../components/Metadata';

const IndexPage = () => {
  return (
    <Template>
      <Metadata pageTitle="index" />
      <Slideshow />
    </Template>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
