import React from 'react';

const WordSplit = ({ text }) => {
  const result = (
    <span
      dangerouslySetInnerHTML={{
        __html: text.replace(
          /\w+|\s+|[^\s\w]+/g,
          '<span class="word">$&</span>'
        ),
      }}
    />
  );
  return result;
};

export default WordSplit;
